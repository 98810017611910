import React from "react";
import { Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import "../../styles/FAQAccordion.scss";
import DataFAQ from "../../Data/FAQData";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none",
        },
        ".expandIconWrapper": {
          display: "none",
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block",
        },
      }}
    >
      <div className="expandIconWrapper">-</div>
      <div className="collapsIconWrapper">+</div>
    </Box>
  );
};

export default function FAQAccordion() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Box className="accordionWrapper">
      {DataFAQ.map((content) => (
        <Accordion
          className="accordionContainer"
          expanded={expanded === `${content.panel}`}
          onChange={handleChange(`${content.panel}`)}
        >
          <AccordionSummary
            className="accordionSummary"
            expandIcon={<CustomExpandIcon />}
          >
            <Typography>{content.question}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordionDetails">
            <Typography>{content.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}
