import React from "react";
import Layout from "../components/base-structure/Layout";
import { Container, Box, Typography } from "@mui/material";
import FAQAccordion from "../components/faq/FAQAccordion";
import Seo from "../components/common/SEO";
import "../components/home/FAQ.scss";

const content = [
  {
    id: 1,
    metaTitle: "Portfolio & Integration FAQs - Invocom product guide",
    metaDescription:
      "Answered questions we've often asked: integrate a chatbot into your website, features in invocom, enable desktop notifications in invocom & subscription cost.",
  },
];
export default function faq() {
  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="faqs/"
          />
        </div>
      ))}
      <div className="faqBG">
        {/* FAQ Page */}
        <Container>
          <Box className="faqHeadingPage">
            <Typography variant="h1">FAQ's</Typography>
          </Box>
          <Box>
            <FAQAccordion />
          </Box>
        </Container>
      </div>
      <script type="application/ld+json">
        {`
            {
  "@context": "https://schema.org/",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Why Do Businesses Need an AI Chatbot?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Using an AI chatbot helps retain website visitors' attention for longer by promptly responding to their questions and providing them with helpful and engaging information about company products and services. Simultaneously, the chatbot collects information, feedback, reviews and personalized user experience."
      }
    },
    {
      "@type": "Question",
      "name": "What is the primary use of an AI chatbot?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "AI chatbots are used in messaging apps and interactive series of communication. To provide a better user experience, these AI-powered chatbots employ a branch of AI known as natural language processing (NLP). These NLP chatbots, sometimes virtual agents or intelligent virtual assistants, aid human agents by handling repetitive and time-consuming contacts. Customers may profit from the extra convenience of this technology since it employs automated algorithms that connect with customers in a human-like way at little cost."
      }
    },
    {
      "@type": "Question",
      "name": "How do AI Chatbots work in e-commerce?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "AI-powered e-commerce chatbots give consumers an everyday experience. They provide information, reply to inquiries, and recommend new products and services. Regardless of the time of day they are always available. The always-on nature of e-commerce chatbots is crucial to their effectiveness."
      }
    },
    {
      "@type": "Question",
      "name": "Are chatbots good for a website?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Chatbots can help increase conversion on your website. 55% of companies that follow suit produce more qualified leads, so you should too. You can easily engage, and automate your sales process using a chatbot powered by artificial intelligence."
      }
    },
    {
      "@type": "Question",
      "name": "What security concerns are there with bots?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Frequently, one-time events like virus assaults, phishing schemes, and distributed denial of service (DDoS) attacks are categorized as threats. Long-term issues, including vulnerabilities, must be addressed continuously. There are security steps you can take if you decide to employ chatbots to make them safer."
      }
    },
    {
      "@type": "Question",
      "name": "When will the AI chatbot escalate to a human agent?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In contact centers, escalation is the transfer of contact, such as a phone call or chat session, to a supervisor or a more experienced employee. Frequently, escalation occurs when the initial agent handling a problem realizes they need more information or authority to regulate it adequately. It could also happen if an unhappy customer requests that the agent's manager or supervisor handle the situation."
      }
    },
    {
      "@type": "Question",
      "name": "Do chatbots improve customer experience?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The companies often use bots to improve the quality of the customer experience. If a corporation can serve its customers at any time using a chatbot, the customers will be satisfied since they will obtain the service they demand."
      }
    },
    {
      "@type": "Question",
      "name": "Are chatbots good for customer service?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Chatbots reduce response times and customer service expenses by up to 30 percent. 95% of people believe chatbots will significantly improve customer service. Chatbots are anticipated to save corporations $8 billion by 2022. They provide timely and efficient user support to address questions as they occur. Artificial intelligence (AI) chatbots uses NLP technology to analyze, understand, and reply to customer-provided data, as opposed to using a tool with pre-programmed replies."
      }
    },
    {
      "@type": "Question",
      "name": "Where do you store data?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Conversations with chatbots may be stored in an SQL database hosted in the cloud. For instance, if you intended to create a chatbot for the digital platform, you could hold your conversations in InvoCom or connect us with info@invocom.io."
      }
    }
  ]
}

        `}
      </script>
    </Layout>
  );
}
